import React,{useState,useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { TenantFrontendApi_GetTenant,TenantFrontendApi_GetUserList,TenantFrontendApi_GetLicenceList,TenantFrontendApi_BlockUser,TenantFrontendApi_DeleteUser} from "./TenantFrontendApi";
import { Translate } from "./Translator"

import Header from "./Header"
import MainMenu from "./MainMenu"
import BlueBar from "./BlueBar"
import Footer from "./Footer"

import HomeLogo from "./Assets/HomeLogo.png"
import SearchButton from "./Assets/SearchButton.png"
import EditLogo from "./Assets/EditLogo.png";
import Trash from './Assets/Trash.png'
import PlusIcon from "./Assets/PlusIcon.jpg"
import Block from './Assets/Block.png'

import './Tenant.css';                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  

function Tenant(){

    const [mRepaint,SetRepaint] = useState()
    const navigate = useNavigate();

    const [mTenantGuid, SetTenantGuid] = useState()
    const [mTenant, SetTenant] = useState(null)

    const [mLicenceList,SetLicenceList]=useState([])
    

    const [mUserSearchValue,SetUserSearchValue] = useState("")
    const [mDeleteUser,SetDeleteUser] = useState("")

    const [mAllUserList,SetAllUserList] = useState([])
 
    useEffect(() => {

        async function GetTenant(aTenantGuid) {
            try
            {
                console.log("Getting tenant")
                const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"))
                SetTenant(TenantResponse.data)
            }

            catch (ex)
            {
                var error = ex;
                var errorCode = null;
                try 
                {
                    if (ex.response && ex.response.status) {
                        errorCode = ex.response.status;
                        console.log(errorCode)
                    }
                    navigate('../error?errorcode=' + errorCode + '&error=tenant')
                    error = ex.response.data.Exception;
                } catch {}  
                console.log("Error: " + error);
            }
        }

        async function GetUsers(aTenantGuid)
        {
            try{
                console.log("Getting Users")
                const UserListResponse = await TenantFrontendApi_GetUserList(sessionStorage.getItem("token"))
                SetAllUserList(UserListResponse.data)
            }
            catch(ex){
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        async function GetLicences()
        {
            try{
                console.log("Getting Licenses")
                const LicenceListResponse = await TenantFrontendApi_GetLicenceList(sessionStorage.getItem("token"))
                SetLicenceList(LicenceListResponse.data)
            }
            catch(ex){
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }
       
        SetTenantGuid(sessionStorage.getItem('tenantguid'))
        
        if(sessionStorage.getItem("UserIsTenantAdmin") === '1')
        {
            GetTenant(sessionStorage.getItem('tenantguid'))
            GetUsers(sessionStorage.getItem('tenantguid'))
            GetLicences()

        }
        
        
    }, [navigate]);

    function AddNewUserButton(mTenantGuid){
        navigate('../addnewuser?tenantguid=' + mTenant?.TenantGuid)
    }

    function HomePage()
    {
        return(
            navigate('../')
        )
    }

    function languageChanged()
    {
        SetRepaint(!mRepaint)
    }

    async function DeleteUser()
    {
        try
        {
            console.log("Deleting User")
            const DeleteUserResponse = await TenantFrontendApi_DeleteUser(sessionStorage.getItem("token"),mDeleteUser.UserGuid)
            console.log(DeleteUserResponse)
            SetDeleteUser("")

            const UserListResponse = await TenantFrontendApi_GetUserList(sessionStorage.getItem("token"))
            SetAllUserList(UserListResponse.data)   
        }

        catch(ex){
            console.log(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
            SetDeleteUser("")
        }
    }

    async function BlockUser(aUser)
    {
        try
        {
            console.log("Blocking User")
            const BlockUserResponse = await TenantFrontendApi_BlockUser(sessionStorage.getItem("token"),aUser.UserGuid,aUser.TenantGuid,aUser.IsActive)
            console.log(BlockUserResponse)
            const UserListResponse = await TenantFrontendApi_GetUserList(sessionStorage.getItem("token"),mTenantGuid)
            SetAllUserList(UserListResponse.data) 
        }

        catch(ex){
            console.log(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    if (sessionStorage.getItem("UserIsTenantAdmin") === "0")
    {
        return(navigate('../error?error=tenant&errorcode=403'))
    } 

    else
    {
        const UserFilterLC = mUserSearchValue.toLowerCase()
        const FilteredUserList = mAllUserList.filter(
            user => (
                user.Name.toLowerCase().includes(UserFilterLC) 
                || user.Email.toLowerCase().includes(UserFilterLC))
        )

        return(            
            <div>

                <Header onLanguageChanged={() => languageChanged()}/>
                
                <MainMenu MainMenuNumber={2} />

                <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                    <tbody>                
                        <tr className="pagetop3">
                            <td colSpan={6} height="75" valign="bottom">
                                <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                    <tbody>
                                        <tr height="60">
                                            <td width="40" align="left">
                                                <img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={()=>HomePage()}/>
                                                <span align="left" className="bold">{mTenant?.Company}</span>
                                            </td>                                        
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr className="pagetop4">
                            <td colSpan={4} height="75" valign="left" className="activetenants width30">
                                {mTenant?.Company} {Translate("Information")}
                            </td>
                        </tr>

                        <tr className="pagetop4">
                            <td colSpan={4} className="horizontalLine width30" >
                                <BlueBar/>
                            </td>
                        </tr>

                        <tr height="50"/>                
                         
                    </tbody>
                </table>

                <table className="width1200" align="center">
                    <tbody>

                        <tr className="pagetop6">
                            <td className="activetenants width30" colSpan={3} height="50" valign="left">
                                {Translate("Information")}
                            </td>
                        </tr>  

                        <tr height="10"/> 

                        <tr>
                            <td className="bordertop borderleft bold tenatsname borderbottomright" width="175">{Translate("Company name")}</td>
                            <td className="bordertop companyname borderbottomright">{mTenant?.Company}</td>
                            <td className=" freespacetable" width="40"></td>
                            <td className="bordertop borderleft bold tenatsname borderbottomright" width="200">{Translate("Email")}</td>
                            <td className="bordertop left-padding borderbottomright">{mTenant?.CompanyPhone}</td>
                        </tr>

                        <tr>
                            <td className="borderleft bold tenatsname borderbottomright">{Translate("Address")}</td>
                            <td className=" companyname borderbottomright">{mTenant?.CompanyAddressLine1}</td>
                            <td className=" freespacetable" width="40"></td>
                            <td className=" borderleft bold borderbottomright left-padding">{Translate("VAT number")}</td>
                            <td className=" left-padding borderbottomright">{mTenant?.VATNumber}</td>
                        </tr>

                        <tr className="contentenatable" >
                            <td className=" borderleft bold tenatsname borderbottomright">{Translate("Address")} 2</td>
                            <td className=" companyname borderbottomright">{mTenant?.CompanyAddressLine2}</td>
                            <td className=" freespacetable" width="40"></td>
                            <td className=" bold borderleft borderbottomright">{Translate("Custom id")}</td>
                            <td className="left-padding borderbottomright">{mTenant?.CustomId}</td>
                        </tr>
                    
                        <tr className="contentenatable" >
                            <td className="borderleft bold tenatsname borderbottomright">{Translate("Postal code")}</td>
                            <td className=" companyname borderbottomright">{mTenant?.CompanyPostalCode}</td>
                            <td className=" freespacetable" width="40"></td>
                            <td className=" bold borderleft borderbottomright">{Translate("Tenant API settings")}</td>
                            <td className="left-padding borderbottomright">{mTenant?.ApiSettings}</td>
                        </tr>

                        <tr className="contentenatable" >
                            <td className="borderleft bold tenatsname borderbottomright">{Translate("City")}</td>
                            <td className=" companyname borderbottomright">{mTenant?.CompanyCity}</td>
                            <td className=" freespacetable" width="40"></td>
                            <td className=" bold borderleft borderbottomright">{Translate("Country")}</td>
                            <td className="left-padding borderbottomright">{mTenant?.CompanyCountry}</td>
                        </tr>

                        <tr height="50"/>
                    
                        <tr className="pagetop6">
                            <td className="activetenants width30" colSpan={3} height="50" valign="left">{Translate("Bought licenses")}</td>
                            <td colSpan={2} align="right">
                            </td>
                        </tr>

                        <tr height="10"/>

                    </tbody>
                </table>

                <table className="width1200" cellPadding={0} cellSpacing={0} align="center">
                    <tbody>
                        <tr>
                            <td className="tenantlicencetableheader borderleft bordertop borderright">{Translate("License")}</td>
                            <td className="borderleft tenantlicencetableheader tenantlicencetableheaderdescription  bordertop borderright">{Translate("Description")}</td>
                            <td className="borderleft tenantlicencetableheader tenantlicencetableheaderQuantity bordertop borderright">{Translate("Quantity")}</td>
                        </tr>

                        {mLicenceList && mLicenceList.length > 0 ? (
                        mLicenceList.map((licence, index) => (
                        <tr  key={index}>
                            <td className="borderleft borderbottom bold bordertop">{licence.Name}</td>
                            <td className="borderleft borderbottom bordertop">{licence.Description}</td>
                            <td className="borderright borderbottom borderleft borderbottom paddingleft bordertop">{licence.Count}</td>
                        </tr>))):(

                        <tr>
                            <td className="borderleft borderbottom borderright bold" align="center" colSpan={5}>{Translate("No license available")}</td>
                        </tr>)}

                        <tr>
                            <td colSpan={4} height="80"></td>
                        </tr>

                        <tr>
                            <td colSpan={4} className="activetenants width30"  height="50" valign="left">{Translate("Users")}</td>
                        </tr>

                        <tr>
                            <td className="width50" colSpan={2} height="50" valign="left">
                                <input id="searchvalue" value={mUserSearchValue} type="text" placeholder={Translate("Search") ?? ""} className="searchtenants" onChange={evt => SetUserSearchValue(evt.target.value)}/>
                                <img alt="Search" className="searchimage" src={SearchButton} onClick={() => SetRepaint(!mRepaint)} />
                            </td>
                            <td colSpan={2} align="right" width="323">
                                <table className="blackbackground borderradius">
                                    <tbody>
                                        <tr className="pointer" onClick={()=>AddNewUserButton(mTenant?.TenantGuid)}>
                                            <td width="8"/>
                                            <td><img className="width50" src={PlusIcon} alt="plus icon"/></td>
                                            <td className="bold white">{Translate("Add user")}</td>
                                            <td width="20"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr height="10"/>

                    </tbody>
                </table>

                <table className="width1200" cellPadding={0} cellSpacing={0} align="center" border="1">
                    <tbody>
                        <tr>
                            <td className="tenantusertable tenantusertableheadername borderleft" >{Translate("Name")}</td>
                            <td className="tenantusertable tenantusertableheadername" >{Translate("E-mail / Sign-in")}</td>
                            <td className="tenantusertable tenantusertableheadername " >{Translate("Assigned license")}</td>
                            <td colSpan={3} className="tenantusertable ">{Translate("Change")}</td>
                        </tr>

                        {FilteredUserList.length > 0 ? (
                        FilteredUserList.sort((a,b) => a.Name.localeCompare(b.Name)).map((user, index) => (
                        <tr height="60" key={index} className={user.IsActive ? "" : "lightfontcolor"}>
                            <td className="tenantusertablecontent borderbottom bold underline pointer" onClick={()=>navigate('../user?userguid=' + user.UserGuid + '&tenantguid=' + mTenantGuid)}>{user.Name}</td>
                            <td className="tenantusertablecontent borderbottom">{user.Email}</td>
                            <td className="tenantusertablecontent underlinetext borderbottom">{user.LicenceName}</td>
                            <td className=" borderbottom">
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <img align="right" alt="edit" src={EditLogo}></img>
                                                            </td>
                                                            <td>
                                                                <td className=" underlinetext tenantusertableedit pointer" onClick={() => navigate('../edituser?userguid=' + user.UserGuid + '&tenantguid=' + mTenantGuid)}>{Translate("Edit")}</td>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                
                                            </td>
                                           
                                            <td><img className="trashwidth" align="right" alt="DeleteLogo" src={Trash}></img></td>
                                            <td align="left" valign="center" className=" underlinetext tenantusertableedit pointer" onClick={() => SetDeleteUser(user) }>{Translate("Delete")}</td>
                                            <td><img className="trashwidth" align="right" alt="BlockLogo" src={Block}></img></td>
                                            <td width="*" align="left" valign="center" className = {user.IsActive ? "underlinetext tenantusertableedit pointer" : "underlinetext tenantusertableedit pointer red" } 
                                                onClick={() => BlockUser(user)}>
                                                {user.IsActive ? Translate("Block sign-in") : Translate("Unblock sign-in") }   
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        ))
                        ) : (
                        <tr>
                            <td className="borderleft borderbottom  bold" align="center" colSpan={7}>{Translate("No user found")}</td>
                        </tr>
                        )}            
                </tbody>
            </table>                    

            <table>
                <tbody>
                    <tr height="30"/>
                </tbody>
            </table>          
                
            {/*Footer Table*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            <Footer/>
            

            {/*Animation for deleting a user*/}
            <Modal animation={false} style={{opacity:1}} show={mDeleteUser !== ""} onHide={() => {SetDeleteUser("")}} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{("Are you sure you want to delete this user")}</span></Modal.Title>
                </Modal.Header>

                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => {DeleteUser()}}>{Translate("Delete")}</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" onClick={() => {SetDeleteUser("")}}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            
    </div>)
    }
}

export default Tenant