import React,{useState,useEffect} from "react";
import Cookies from 'js-cookie'

import Header from "./Header";
import { Translate } from "./Translator"
import MainMenu from "./MainMenu";
import { useNavigate } from "react-router-dom";

function Error(){

    const [mError,SetError] = useState()
    const [mErrorCode,SetErrorCode] = useState()

    const [mRepaint,SetRepaint] = useState()
    const navigate = useNavigate()
    
    useEffect(() => {
       
        //Get the parameters from the URL
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const ciParams = new URLSearchParams();
        for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
        const error = ciParams.get('error')
        const errorCode = ciParams.get('errorcode')

        SetError(error)
        SetErrorCode(errorCode)
        
    }, []);

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    function SignInPage()
    {
        sessionStorage.clear()
        Cookies.remove('userportal', { domain: '.cloudcti.nl' })
        window.location.reload()
    }
    
   return(
        <span>

            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber= '6'/>

            <table align="center">
                <tbody>
                    <tr height="40"/>
                    {mErrorCode === '404' ?   <tr>
                                                <td colSpan={3} className="bold">
                                                    {mError === "tenant" ? Translate("Tenant is not found") : Translate("User is not found") }
                                                </td>
                                            </tr>
                                            :
                                            null
                    }
                    {mErrorCode === '403' ?   <tr>
                                                <td colSpan={3} className="bold">You are not authorized to retrieving this {mError === "tenant" ? "tenant" : "user"} please sign in with a different account or going back to home page</td>
                                            </tr>
                                            :
                                            null
                    }
                    <tr height = "60"/>
                    <tr>
                        <td align="center"  ><button type="button"  className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" onClick={()=>navigate('../')}>{Translate("Home page")}</button></td>
                        <td width = "40"></td>
                        <td><button type="button"  className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" onClick={()=>SignInPage()}>{Translate("Sign in page")}</button></td>
                    </tr>
                </tbody>
            </table>
            
        </span>
    )
}

export default Error