import React from "react";
import { useNavigate } from 'react-router-dom';
import { Translate } from "./Translator"

import './Header.css'
import './MainMenu.css'

function MainMenu(props){

    const navigate = useNavigate();

    function ResellerPortal()
    {
        return(window.location.href = 'https://dashboard.cloudcti.nl/')    
    }

    return(
        <span>
            <table className="mainmenubar" width="100%" cellPadding={0} cellSpacing={0} align="center">
                <tbody>                
                    <tr className="pagetop2">
                        <td width="*"/>
                        <td width="1200" height="150" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    <tr height="60">
                                        <td width="220" className={props.MainMenuNumber === 1 ? "mainmenuitemselected" : "mainmenuitem" } onClick={() => navigate('../')}>{Translate("Installation")}</td>
                                        <td width="220" className={props.MainMenuNumber === 2 ? "mainmenuitemselected" : "mainmenuitem" } onClick={() => navigate('../tenant')}>{Translate("Tenant")}</td>
                                        <td width="220" className={props.MainMenuNumber === 3 ? "mainmenuitemselected" : "mainmenuitem" } onClick={() => ResellerPortal()}>{Translate("Reseller Portal")}</td>
                                        <td width="220" className={props.MainMenuNumber === 4 ? "mainmenuitemselected" : "mainmenuitem"} onClick={() => navigate('../support')}>{Translate("Support")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td width="*"/>
                    </tr>
                </tbody>
            </table>
        </span>
    )

}

export default MainMenu