import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'

import Home from './Home'
import Support from "./Support"
import EditUser from './EditUser'

import './App.css'
import Tenant from "./Tenant"
import AddNewUser from "./AddNewUser"
import User from "./User"
import TenantUsers from './TenantUsers'
import Error from './Error'

//IMPORTANT!!
//Signin via the https://signin-va.cloudcti.nl/signin/onelogin ONLY works if the app runs in a *.cloudcti.nl domain
//It will NOT work in a localhost environment, and it will NOT work in an *.azurewebsites.net environment
//For these latter purposes, the debug lines in the App() can be used to force a user

function App() {

  const token = Cookies.get("userportal")
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      console.log(decodedToken)
      console.log(token)
      sessionStorage.setItem("username", decodedToken.UserName);
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("userguid", decodedToken.UserGuid);
      sessionStorage.setItem("tenantguid",decodedToken.TenantGuid);
      sessionStorage.setItem("UserIsTenantAdmin",decodedToken.UserIsTenantAdmin)
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  //sessionStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaWduaW5NZXRob2QiOiJDbG91ZENUSSIsIlNpZ25pblVzZXIiOiJzZG5iQGtqYmRlZi5jb20iLCJTaWduaW5QYXNzd29yZCI6IlRqQnM5RlN6c2dPMmZFWDErdkUvUkxmS053aHVKZ085ejB1N3BlVXNpbGIwS0hqSlNEL2ZQakhwZGdyRHhwRXN4MFA0SjdlWjRzTUNKOThROFYvM1FUQWx0c2FFZ3RoZFRHOHVzNjFHbXlwdUlVNnJweHZ1MDVDOXVOdy9RbnVLIiwiU2lnbmluQXBwbGljYXRpb24iOiJVc2VyUG9ydGFsIiwiVGVuYW50R3VpZCI6IlRlbmFudF8yNDFjN2MxYy1lODA0LTQxYTgtOTk4Mi1mY2UzZDc0OTE4MTQiLCJUZW5hbnROYW1lIjoiQ3JlYXIgUmF0ZSBUZXN0IENvbXBhbnkiLCJVc2VyR3VpZCI6IlRlbmFudFVzZXJfODc3NzJkNGMtZGY5OS00ODc5LThiZDYtZDM1MGI0NjkyZTJmIiwiVXNlck5hbWUiOiJGaW5hbCIsIlVzZXJJc0FkbWluIjoiMSIsIlVzZXJJc1RlbmFudEFkbWluIjoiMSIsIkVtYWlsIjoic2RuYkBramJkZWYuY29tIiwiUm9sZXMiOiJVc2VyIiwiZXhwIjoxNzIxNDY3OTcyLCJpc3MiOiJzaWduaW4tdmEiLCJhdWQiOiJDbG91ZENUSTIwMjIifQ.OMG4pemEMlEmbdLIiG1m9QoDsiXitkW0t9xmrsJCtbc")
  //sessionStorage.setItem("username", decodedToken.UserName);

  if (sessionStorage.getItem("token"))
  {
      return (
        <Router>
          <Routes>
            <Route path="/home" element={<Home/>} />
            <Route path="/" element={<Home/>} />
            <Route path="/support" element={<Support/>} />
            <Route path="/edituser" element={<EditUser/>} />
            <Route path="/tenant" element={<Tenant/>} />
            <Route path="/addnewuser" element={<AddNewUser/>} />
            <Route path="/user" element={<User/>} />
            <Route path="/tenantusers" element={<TenantUsers/>} />
            <Route path="/error" element={<Error/>}/>
          </Routes>
        </Router>
      );
    }

    else
    {   
      const redirecturl = window.location.href.replaceAll(":","%3A").replaceAll("/","%2F").replaceAll("&","%26")   
      window.location.href = "https://signin-va.cloudcti.nl/signinbeta/onelogin?clientid=userportal&redirecturl=" + redirecturl
    } 
  }
  
export default App;

