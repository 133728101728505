import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import HomeLogo from "./Assets/HomeLogo.png"
import Attach from "./Assets/Attach.png"

import Header from './Header'
import MainMenu from './MainMenu'
import { Translate } from "./Translator";
import BlueBar from './BlueBar'
import Footer from './Footer'

function Home(){

    const [mRepaint, SetRepaint] = useState()
    const navigate = useNavigate();

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    return(

        <span>
            
            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber={1} />
                    
            <table className="tenanttable" width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                                    
                    <tr className="pagetop3">
                        <td colSpan={4} height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px" >
                                <tbody>
                                    <tr height="60">
                                        <td width="80" align="left">
                                            <img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/>
                                            <span className="bold">{Translate("User portal")}</span>
                                        </td>                                            
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td className="freespace"/>
                        <td colSpan={4} height="75" valign="left" className="activetenants">
                            {Translate("User portal")}
                        </td>
                    </tr>
                        
                    <tr className="pagetop4">
                        <td className="freespace"/>
                        <td colSpan={4} className="horizontalLine" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>
                </tbody>
            </table>

            <table className="width1200" align="center">
                <tbody>

                    <tr>
                        <td>{Translate("Download and configure the CloudCTI software for your Microsoft Windows PC or tablet")}</td>
                    </tr>

                    <tr height="20"/>

                    <tr>
                        <td>
                            <img className="" alt="Attach" src={Attach}/>
                            <a className="black" href="https://download.cloudcti.nl/files/setups/cloudcticlientsetup.exe">CloudCTI Client Setup</a>
                        </td>
                    </tr>

                    <tr height="30"/>

                    <tr>
                        <td className="bold">{Translate("Documentation")}</td>
                    </tr>

                    <tr height ="20"/>

                    <tr>
                        <td>
                            <img className="" alt="Attach" src={Attach}/>
                            <a className="black" href="https://downloadcloudcti.blob.core.windows.net/files/Docs/CloudCTI%20Client%20Installation%20manual.pdf">CloudCTI Client Installation Manual</a>
                        </td>
                    </tr>

                    <tr height ="20"/>

                    <tr>
                        <td>
                            <img className="" alt="Attach" src={Attach}/>
                            <a className="black" href="https://downloadcloudcti.blob.core.windows.net/files/Docs/CloudCTI%20Client%20Manual.pdf">CloudCTI Client Manual</a>
                        </td>
                    </tr>

                    <tr height="100"/>

                </tbody>
            </table>         

            <Footer/>
            
        </span>    
    )
}

export default Home