import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
import { Modal, Button } from "react-bootstrap";

import {TenantFrontendApi_AddNewUser,TenantFrontendApi_GetLicenceList, TenantFrontendApi_GetTenant} from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header";
import MainMenu from "./MainMenu";
import Footer from "./Footer"
import BlueBar from "./BlueBar";

import HomeLogo from "./Assets/HomeLogo.png"

import './Tenant.css';
import './AddNewUser.css';

function AddNewUser(){

    const navigate = useNavigate()
    const [mRepaint,SetRepaint] = useState()

    const [mUserEmail,SetUserEmail]=useState("")
    const [mUserPassword,SetUserPassword]=useState()
    const [mUserName,SetUserName]=useState()
    const [mUserSettings,SetUserSettings]=useState()
    const [mUserApiSettings,SetUserApiSettings]=useState()
    const [mUserRecognisionAdmin,SetUserRecognisionAdmin] = useState(false)
    const [mUserTenantAdmin,SetUserTenantAdmin] = useState(false)
    const [mUserNotCreated,SetUserNotCreated] = useState("")

    const [mTenantGuid,SetTenantGuid]=useState()
    const [mTenant,SetTenant] = useState("")

    const [mLicenceGuid,SetLicenceGuid]=useState()
    const [mLicenceList,SetLicenceList] = useState([])
    const [mPasswordVisible, SetPasswordVisible] = useState(false);

    const [mSigninMethodValues,SetSignInMethodValues] = useState([])
    const [mSelectedSigninMethod,SelectedSigninMethod] = useState("")

    useEffect(() => {

        async function GetLicenceList() {
            try
            { 
                console.log("Getting Licences")
                const LicenceListResponse = await TenantFrontendApi_GetLicenceList(sessionStorage.getItem("token"))
                SetLicenceList(LicenceListResponse.data)  
            }

            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        async function GetTenant() {
            try
            {
                console.log("Getting Tenant")
                const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"))
                SetTenant(TenantResponse.data)
            }
            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        GetTenant()
        GetLicenceList()
        
    }, []);

    useEffect(() => {
        if (mLicenceList.length > 0) {
          FillSignInMethod();
        }
      }, [mLicenceList]);

    const RemoveDuplicates = (list) => {
        const uniqueNames = [...new Set(list.map(licence => licence.ExtractedValue))];
        const uniqueLicenceList = uniqueNames.map(ExtractedValue => list.find(licence => licence.ExtractedValue === ExtractedValue));
        SetSignInMethodValues(uniqueLicenceList);
      };
    
       

    function AddNewUserButton(){    
        return(
            AddNewUserRequest()
        )
    }
    
    async function AddNewUserRequest() {

        try
        {
            const AddNewUserResponse = await TenantFrontendApi_AddNewUser(sessionStorage.getItem("token"),mUserEmail,mUserPassword,mUserName,mUserSettings,mUserApiSettings,mUserRecognisionAdmin,mTenant.TenantGuid,mLicenceGuid,mUserTenantAdmin)
            console.log(JSON.stringify(AddNewUserResponse))
            navigate('../tenant?tenantguid=' + mTenantGuid)
        }

        catch (ex)
        {
            var error = ex;
            var errorCode = null;
            try 
            {
                if (ex.response && ex.response.status) {
                    errorCode = ex.response.status;
                    console.log(errorCode)
                }
                if(errorCode === 409)
                {
                    // We have the same email address in our database
                    SetUserNotCreated("We have the same Email")

                }
                error = ex.response.data.Exception;
                } catch {}  
                console.log("Error: " + error);
        }
    }

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    function CheckAllRequirment()
    {
        if (mUserEmail)
        {
            return false;
        }
        else
        {
            return true;
        }
    }

    const ExtractValueInParentheses = (Name) => {
        const Match = Name.match(/\(([^)]+)\)/);
        return Match ? Match[1] : null;
    };
    
    const FillSignInMethod = () => {
        const UpdatedValues = mLicenceList.map(licence => ({
          ...licence,
          ExtractedValue: ExtractValueInParentheses(licence.Name)
        }));
        
        RemoveDuplicates(UpdatedValues)
    };

    let PageLocation = <span/>
    PageLocation = <tr height="60">
                        <td width="40" align="left">
                            <span width="20" align="left"><img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/></span>              
                            <span className="underline pointer bold" width="40" align="left" text-align="button" onClick={() => navigate('../reseller')}>{Translate("Tenants")}</span>
                            <span width="20" className="blue" align="center"> / </span>
                            <span className="underline pointer bold" width="20" align="left" onClick={() => navigate('../tenant?tenantguid=' + mTenantGuid)}>{mTenant.Company}</span>
                            <span width="20" className="blue" align="center"> / </span>
                            <span className="bold" width="40" align="left" text-align="button" >{Translate("Add new user")}</span> 
                        </td>
                    </tr>

    return(
        <span>

            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber={2} />

            <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                    <tbody>
                        <tr className="pagetop3">
                            <td height="75" valign="bottom">
                                <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                    <tbody>
                                        {PageLocation}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            
            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                <tbody>                

                    <tr className="pagetop4">
                        <td colSpan={4} height="75" valign="left" className="activetenants width30">
                            {Translate("Add user")}
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td colSpan={4} className="horizontalLine width30" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>                
                </tbody>
            </table>

            <table className="width1200 cloudctibackgroundtable" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                    
                    <tr height="20"/>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Name")}<span className="red">*</span></td>
                    </tr>

                    <tr height="10"/>

                    <tr  height="40">
                        <td width="40"></td>
                        <td width="1000" >
                            <input className="inputtd" onChange={e=>SetUserName(e.target.value)}></input>
                        </td>
                        <td></td>
                    </tr>

                    <tr height="30"></tr>

                    {/*<tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Phone connection")}</td>
                    </tr>

                    <tr height="10"></tr>

                    <tr  height="40">
                        <td width="40"></td>
                        <td width="800" className="">
                            <select className="inputtd" onChange={e=>SelectedSigninMethod(e.target.value)}>
                                <option value="">{Translate("Please select the phone system method")}</option>
                                {mSigninMethodValues.map((licence) => (
                                    <option key={licence.LicenceGuid} value={licence.ExtractedValue}>
                                        {licence.ExtractedValue} {Translate("Same sign-on")}
                                    </option>
                                ))}
                                {mSigninMethodValues.map((licence) => (
                                    <option key={licence.LicenceGuid} value={licence.ExtractedValue}>
                                        {licence.ExtractedValue} {Translate("CloudCTI sign-in")}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>

                            <tr height="30"></tr>*/}

                    <tr>
                        <td className="width30"></td>
                        <td className="bold fontsizesmall">{Translate("E-mail")} <span className="red">*</span></td>
                    </tr>

                   <tr height="10"/>

                    <tr  height="40">
                        <td width="40"></td>
                        <td width="1000">
                            <input className="inputtd" placeholder={Translate("E-mail")} onChange={e=>SetUserEmail(e.target.value)}></input>
                        </td>
                        <td></td>
                    </tr>

                    <tr height="30"/>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Password")}</td>
                        
                    </tr>

                    <tr height="10"/>
                    <tr  height="40">
                        <td width="40"></td>
                        <td width="1000">
                            <input type={mPasswordVisible ? 'text' : 'password'} className="inputtd" onChange={e=>SetUserPassword(e.target.value)}></input>
                            <span className="passwordtoggleicon" onClick={() => SetPasswordVisible(!mPasswordVisible)}>{mPasswordVisible ? '👁️' : '👁️‍🗨️'} </span>
                        </td> 
                    </tr>

                    <tr height="20"/>

                    <tr>
                        <td width="40"></td>
                        <td width="100"><PasswordStrengthBar password={mUserPassword} /></td>
                    </tr>
                                        
                    <tr height="20" ></tr>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Settings")} 
                            
                        </td>
                    </tr>

                    <tr height="10"/>

                    <tr  height="40">
                            <td width="40"></td>
                            <td width="1000" className="">
                                <textarea className="textareaadduser" onChange={e=>SetUserSettings(e.target.value)}></textarea>
                            </td>
                            <td></td>
                    </tr>

                    <tr height="20"/>
                    
                    <tr height="20" ></tr>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("API settings")} 
                            
                        </td>
                    </tr>
                    
                    <tr height="10"/>
                    
                    <tr  height="40">
                        <td width="40"></td>
                        <td width="1000" className="">
                            <textarea className="textareaadduser" onChange={e=>SetUserApiSettings(e.target.value)}></textarea>
                        </td>
                        <td></td>
                    </tr>
                
                    <tr height="30" ></tr>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall"><input  onChange={e=>SetUserRecognisionAdmin(e.target.value ? true : false)} type="checkbox"/> <span> </span> 
                            {Translate("CRM Integration Admin; required for to configure the CRM integration settings of the user’s organization.")}
                        </td>
                    </tr>

                    <tr height="30" ></tr>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall"><input  onChange={e=>SetUserTenantAdmin(e.target.value ? true : false)} type="checkbox"/> <span> </span> 
                            {Translate("User Account Admin; required to configure other the organization’s other user accounts.")}
                            
                        </td>
                    </tr>
                
                    <tr height="30"/>
                
                    <tr  height="40">
                        <td width="40"></td>
                        <td width="800" className="">
                            <select className="inputtd" onChange={e=>SetLicenceGuid(e.target.value)}>
                                <option value="">{Translate("Please select the license")}</option>
                                <option value="">{Translate("None")}</option>
                                {mLicenceList.map((licence) => (
                                    <option key={licence.id} value={licence.LicenceGuid}>
                                        {licence.Name}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>

                    <tr height="30"/>
                
                    <tr>
                        <td width="40"/>
                        <td><button disabled={CheckAllRequirment()}  type="button" className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" onClick={()=>AddNewUserButton()}>{Translate("Add user")}</button></td>
                    </tr>
                                    
                    <tr height="20"/>
                
                </tbody>
            </table>
            
            {/*Free space*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            {/*Footer Table*/}
            <Footer/>

            {/*Animation part*/}
            <Modal animation={false} style={{opacity:1}} show={mUserNotCreated !== ""} onHide={() => {SetUserNotCreated("")}} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{("A user with this email address is already registered, Please try with another email address.")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => {SetUserNotCreated("")}}>OK</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                </Modal.Footer>
            </Modal>

        </span>
    )
}

export default AddNewUser