import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Translate } from "./Translator"

import Header from "./Header";
import MainMenu from "./MainMenu"
import BlueBar from "./BlueBar";
import Footer from "./Footer"

import HomeLogo from "./Assets/HomeLogo.png"
import Attach from "./Assets/Attach.png"
import WebPageIcon from './Assets/WebPageIcon.png'

function Support()
{
    const [mRepaint,SetRepaint] = useState()
    const navigate = useNavigate();

    function languageChanged()
    {
        SetRepaint(!mRepaint)
    }

    return(
        <div>

            <Header onLanguageChanged={() => languageChanged()}/>
            <MainMenu MainMenuNumber={4} />

            <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                    <tr className="pagetop3">
                        <td height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    <tr height="60">
                                        <td width="40" align="left">
                                            <span width="20" align="left"><img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/></span>              
                                            <span className=" bold" width="40" align="left" text-align="button" onClick={() => navigate('../support')}>{Translate("Support")}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                <tbody>

                    <tr className="pagetop4">
                        <td colSpan={4} height="75" valign="left" className="activetenants width30">
                            {Translate("Support")}
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td colSpan={4} className="horizontalLine width30" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>

                    <tr>
                        <td className="bold">
                            {Translate("Please find a range of support resources on these pages. More will be added soon.")}
                        </td>
                    </tr>

                    <tr height="20"/>

                    <tr>
                        <td className="bold">{Translate("Contact")}</td>
                    </tr>
                    
                    <tr height="10"/>

                    <tr>
                        <td> <a className="paddingleft" href="mailto:support@cloudcti.nl"> Support@cloudcti.nl </a> </td>
                    </tr>

                    <tr height="10"/>

                    <tr>
                        <td> <a className="paddingleft" href="tel:+31 35 699 02 46"> +31 35 699 02 46 </a> </td>
                    </tr>

                    <tr height="20"/>

                    <tr>
                        <td className="bold">{Translate("FAQ")}</td>
                    </tr>
                    
                    <tr height="10"/>

                    <tr>
                        <td>
                            <img alt="WebPageIcom" src={WebPageIcon}/>
                            <a className="black paddingleft" href="https://cloudcti.nl/support/faq">https://cloudcti.nl/support/faq</a>
                        </td>
                    </tr>

                    <tr height="30"/>

                    <tr>
                        <td className="bold">{Translate("Supported CRM Integrations")}</td>
                    </tr>
                    
                    <tr height="10"/>

                    <tr>
                        <td>
                            <img alt="Attach" src={WebPageIcon}/>
                            <a className="black paddingleft" href="https://cloudcti.nl/crm-applications">https://cloudcti.nl/crm-applications</a>
                        </td>
                    </tr>

                    <tr height="30"/>

                    <tr>
                        <td className="bold">{Translate("Manuals & documentation")}</td>
                    </tr>
                    
                    <tr height="10"/>

                    <tr>
                        <td>
                            <img alt="Attach" src={WebPageIcon}/>
                            <a className="black paddingleft" href="https://downloadcloudcti.blob.core.windows.net/files/Docs/CloudCTI%20Hosts%20and%20Ports.htm">CloudCTI Hosts and Ports</a>
                        </td>
                    </tr>

                    <tr height="10"/>
                    
                    <tr>
                        <td>
                            <img alt="Attach" src={WebPageIcon}/>
                            <a className="black paddingleft" href="https://downloadcloudcti.blob.core.windows.net/files/Docs/CloudCTI%20Troubleshooting.htm">Troubleshooting CloudCTI</a>
                        </td>
                    </tr>

                    <tr height="30"/>

                    <tr>
                        <td className="bold">{Translate("Setup")}</td>
                    </tr>

                    <tr>
                        <td>
                            <img alt="Attach" src={Attach}/>
                            <a className="black" href="https://download.cloudcti.nl/files/setups/cloudcticlientsetup.exe">CloudCTI Client Setup</a>
                        </td>
                    </tr>

                    <tr height="10"/>

                    <tr>
                        <td>
                            <img alt="Attach" src={Attach}/>
                            <a className="black" href="https://download.cloudcti.nl/files/setups/cloudcticonfigurationsetup.exe">CloudCTI Configuration Setup</a>
                        </td>
                    </tr>

                    <tr height="200"/>                
                    
                </tbody>
            </table>

            <Footer/>

        </div>
    )
}

export default Support