import axios from "axios";

export async function TenantFrontendApi_ChangeUserPassword(aSecurityToken,aUserGuid,aUserPassword,aTenantGuid)
{
    return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user",
        {
            "UserGuid":aUserGuid,
            "Password" : aUserPassword,
            "TenantGuid":aTenantGuid,


            "EmailUpdate":false,
            "PasswordUpdate":true,
            "NameUpdate":false,
            "SettingsUpdate":false,
            "ApiSettingsUpdate":false,
            "IsActiveUpdate":false,
            "IsRecognitionAdminUpdate":false,
            "IsTenantAdminUpdate":false,
            "IsGlobalAdminUpdate":false,
            "LicenceGuidUpdate":false,
            "LicenceGuidAutoDecreaseTenantLicence":false,
            "LicenceGuidAutoIncreaseTenantLicence":false,
            "ServerScriptsUpdate":false
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )
}

export async function TenantFrontendApi_GetTenant(aSecurityToken)
{
    return await axios.get( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/tenant",                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }                
    )
}

export async function TenantFrontendApi_GetUserList(aSecurityToken)
{
    return await axios.get( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/userlist?&returninactive=true",                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }                
    )

}

export async function TenantFrontendApi_GetLicenceList(aSecurityToken)
{
    return await axios.get( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/tenantlicencelist",                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }   
    ) 
}

export async function TenantFrontendApi_DeleteUser(aSecurityToken,aUserGuid)
{
    return await axios.delete( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/User?userguid=" + aUserGuid,                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }   
    )
}

export async function TenantFrontendApi_BlockUser(aSecurityToken,aUserGuid,aTenantGuid,aUserActiveStatus)
{
    return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user",
        {
            "UserGuid":aUserGuid,
            "TenantGuid":aTenantGuid,
            "IsActive":!aUserActiveStatus,

            "EmailUpdate":false,
            "PasswordUpdate":false,
            "NameUpdate":false,
            "SettingsUpdate":false,
            "ApiSettingsUpdate":false,
            "IsActiveUpdate":true,
            "IsRecognitionAdminUpdate":false,
            "IsTenantAdminUpdate":false,
            "IsGlobalAdminUpdate":false,
            "LicenceGuidUpdate":false,
            "LicenceGuidAutoDecreaseTenantLicence":false,
            "LicenceGuidAutoIncreaseTenantLicence":false,
            "ServerScriptsUpdate":false
        },

        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )

}

export async function TenantFrontendApi_AddNewUser(aSecurityToken,aUserEmail,aUserPassword,aUserName,aUserSettings,aUserApiSettings,aUserRecognisionAdmin,aTenantGuid,aLicenceGuid,aUserTenantAdmin)
{
    return await axios.post( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user?licenceautoincrease=true&createdby=TenantFrontEndApi",
        {
            "UserGuid":"",
            "Email":aUserEmail,
            "Password":aUserPassword,
            "Name":aUserName,
            "Settings":aUserSettings,
            "ApiSettings":aUserApiSettings,
            "IsActive":true,
            "IsRecognitionAdmin":aUserRecognisionAdmin,
            "IsTenantAdmin":aUserTenantAdmin,
            "IsGlobalAdmin":false,
            "TenantGuid":aTenantGuid,
            "LicenceGuid":aLicenceGuid,
            "ServerScripts":true
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )
}

export async function TenantFrontendApi_GetUser(aSecurityToken,aUserGuid)
{
    return await axios.get( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/User?userguid=" + aUserGuid,                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }   
    )
}

export async function TenantFrontendApi_DeleteUserLicence(aSecurityToken,aUserGuid,aTenantGuid)
{
    return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user",
        {
            "UserGuid":aUserGuid,
            "TenantGuid":aTenantGuid,
            "LicenceGuid":"",

            "EmailUpdate":false,
            "PasswordUpdate":false,
            "NameUpdate":false,
            "SettingsUpdate":false,
            "ApiSettingsUpdate":false,
            "IsActiveUpdate":false,
            "IsRecognitionAdminUpdate":false,
            "IsTenantAdminUpdate":false,
            "IsGlobalAdminUpdate":false,
            "LicenceGuidUpdate":true,
            "LicenceGuidAutoDecreaseTenantLicence":false,
            "LicenceGuidAutoIncreaseTenantLicence":false,
            "ServerScriptsUpdate":false
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )
}

export async function TenantFrontendApi_UpdateUser(aSecurityToken,aUserGuid,aEmail,aName,aUserApiSetting,aEnableRecognitionConfiguration,aIsTenantAdministrator,aTenantGuid,aLicenceGuid)
{
    return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user",
        {
            "UserGuid":aUserGuid,
            "Email":aEmail,
            "Name":aName,
            "ApiSettings":aUserApiSetting,
            "IsRecognitionAdmin":aEnableRecognitionConfiguration,
            "IsTenantAdmin":aIsTenantAdministrator,
            "TenantGuid":aTenantGuid,
            "LicenceGuid": aLicenceGuid,

            "EmailUpdate":true,
            "PasswordUpdate":false,
            "NameUpdate":true,
            "SettingsUpdate":false,
            "ApiSettingsUpdate":true,
            "IsActiveUpdate":false,
            "IsRecognitionAdminUpdate":true,
            "IsTenantAdminUpdate":true,
            "IsGlobalAdminUpdate":false,
            "LicenceGuidUpdate":true,
            "LicenceGuidAutoDecreaseTenantLicence":false,
            "LicenceGuidAutoIncreaseTenantLicence":false,
            "ServerScriptsUpdate":false
        },

        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )

}

export async function TenantFrontendApi_ChangeUserLicense(aSecurityToken,aUserGuid,aTenantGuid,aLicenseGuid)
{

   return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user",
        {
            "UserGuid":aUserGuid,
            "TenantGuid":aTenantGuid,
            "LicenceGuid":aLicenseGuid,
            
            "EmailUpdate":false,
            "PasswordUpdate":false,
            "NameUpdate":false,
            "SettingsUpdate":false,
            "ApiSettingsUpdate":false,
            "IsActiveUpdate":false,
            "IsRecognitionAdminUpdate":false,
            "IsTenantAdminUpdate":false,
            "IsGlobalAdminUpdate":false,
            "LicenceGuidUpdate":true,
            "LicenceGuidAutoDecreaseTenantLicence":false,
            "LicenceGuidAutoIncreaseTenantLicence":false,
            "ServerScriptsUpdate":false
        },

        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )

}